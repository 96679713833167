<template>
  <!-- ======= Counts Section ======= -->
  <section id="services" class="counts">
    <div class="container" data-aos="fade-up">
      <div class="row no-gutters">
        <div
          class="image col-xl-5 d-flex align-items-stretch justify-content-center justify-content-lg-start"
          data-aos="fade-right"
          data-aos-delay="100"
        ></div>
        <div
          class="col-xl-7 ps-0 ps-lg-5 pe-lg-1 d-flex align-items-stretch"
          data-aos="fade-left"
          data-aos-delay="100"
        >
          <div class="content d-flex flex-column justify-content-center w-100 p-1">
            <h2 class="text-center">Check our services</h2>
            <hr class="hr-title" />
            <br /><br /><br />
            <div class="row">
              <div
                v-for="(ser, i) in siteData.services"
                :key="i"
                class="col-md-6 d-md-flex align-items-md-stretch"
                data-aos="fade-up"
                data-aos-duration="800"
              >
                <div class="count-box">
                  <i class="bi bi-card-checklist"></i>
                  <span class="purecounter">{{ ser.title }}</span>
                  <p>{{ ser.description }}</p>
                </div>
              </div>
            </div>
          </div>
          <!-- End .content-->
        </div>
      </div>
    </div>
  </section>
  <!-- End Counts Section -->
</template>

<script>
export default {
  computed: {
    siteData() {
      return this.$store.getters.siteData;
    },
  },
};
</script>
