<template>
  <!-- ======= Hero Section ======= -->
  <section id="hero" class="d-flex align-items-center justify-content-center mb-1">
    <div
      style="
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        background: #000;
        outline: none;
      "
      class="video"
    >
      <video
        autoplay
        loop
        preload="auto"
        muted
        height="100%"
        width="100%"
        style="object-fit: cover"
      >
        <source :src="siteData.video.replace('http://', 'https://')" type="video/mp4" />
      </video>
    </div>
    <div class="layerr d-flex align-items-center justify-content-center">
      <div class="container p-1">
        <div class="" data-aos="fade-up">
          <div class="row justify-content-center" style="">
            <h1 data-aos="fade-up" data-aos-delay="300" class="">
              <span class="animated-title">{{ siteData.siteName.split(" ")[0] }}</span>
              <span class="animated-title" style="display: block">{{
                siteData.siteName.split(" ")[1]
              }}</span>
            </h1>
            <div class="line"></div>
            <h2 class="animated-text">{{ siteData.description }}</h2>
            <h3 class="text-center p-1" style="color: #ffffffd4">
              <span>
                <i class="bi bi-phone"></i>
                {{ siteData.phone }}
              </span>
            </h3>
            <h2></h2>
            <a href="#cta" class="hero-btn">payment & transaction</a>
          </div>
        </div>
        <!------------------------------------>
        <hr />
        <social />
      </div>
    </div>
  </section>
  <!-- End Hero -->
</template>

<script>
import Social from "../social-icons";
export default {
  components: {
    Social,
  },
  computed: {
    siteData() {
      return this.$store.getters.siteData;
    },
  },
};
</script>

<style lang="scss" scoped>
.layerr {
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(83, 82, 82, 0.544);
  width: 100%;
  height: 100%;
  padding-top: 0px;
}
.hero-btn {
  border: 2px solid #fff;
  color: #fff;
  padding: 20px;
  text-align: center;
  width: 60%;
  margin: 0 auto;
  &:hover {
    background: rgba(83, 82, 82, 0.531);
  }
  @media (max-width: 320px) {
    font-size: 0.7rem;
    padding: 5px;
  }
}
</style>
