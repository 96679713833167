<template>
  <!-- ======= Contact Section ======= -->
  <section id="contact" class="contact">
    <div class="container" data-aos="fade-up">
      <div class="section-title">
        <h2>Contact</h2>
        <p style="font-size: 1.8rem">Contact Us</p>
      </div>

      <div class="row mt-5">
        <div class="col-lg-4">
          <div class="info">
            <div class="address">
              <i class="bi bi-geo-alt"></i>
              <h4>Location:</h4>
              <p>{{ siteData.address }}</p>
            </div>

            <div class="email">
              <i class="bi bi-envelope"></i>
              <h4>Email:</h4>
              <p>{{ siteData.email }}</p>
            </div>

            <div class="phone">
              <i class="bi bi-phone"></i>
              <h4>Call:</h4>
              <p>{{ siteData.phone }}</p>
            </div>
          </div>
        </div>
        <div class="col-lg-8">
          <div>
            <!-- <iframe
          style="border: 0; width: 100%; height: 270px"
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d17097.433213460943!2d-74.0062269!3d40.7101282!3m2!"
          frameborder="0"
          allowfullscreen
        ></iframe> -->
            <gmap :latest_coords="siteData.coords" />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  computed: {
    siteData() {
      return this.$store.getters.siteData;
    },
  },
};
</script>
