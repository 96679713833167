<template>
  <div id="app">
    <!--Header-->
    <app-header />
    <!-- End Header -->

    <!-- ======= Hero Section ======= -->
    <hero />
    <!-- <social /> -->
    <!-- End Hero -->

    <main id="main">
      <!-- ======= Features Section ======= -->
      <instructions />
      <!-- End Features Section -->

      <!-- ======= Services Section ======= -->
      <systems />
      <!-- End Services Section -->

      <!-- ======= Cta Section ======= -->
      <section
        id="cta"
        class="cta"
        :style="{
          backgroundSize: 'cover',
        }"
      >
        <div class="container" data-aos="zoom-in">
          <div class="text-center">
            <h3>payment & transactions</h3>
            <!-- <p>Duis aute irure dolor in reprehenderit in voluptate.</p> -->
            <a
              @click="() => $router.push('payment')"
              class="cta-btn"
              style="cursor: pointer; width: 40%"
              >Pay</a
            >
          </div>
        </div>
      </section>
      <!-- End Cta Section -->

      <!-- ======= Counts Section ======= -->
      <br /><br />
      <hr />
      <services />
      <hr />
      <br /><br />
      <!-- End Counts Section -->

      <!-- ======= Testimonials Section ======= -->
      <div id="info">
        <info />
      </div>
      <!-- End Testimonials Section -->

      <!-- ======= Team Section ======= -->
      <!-- <team /> -->
      <!-- End Team Section -->

      <!-- ======= Contact Section ======= -->
      <contact />
      <!-- End Contact Section -->
    </main>
    <!-- End #main -->

    <!-- ======= Footer ======= -->
    <app-footer />
    <!-- End Footer -->

    <!-- <div id="preloader"></div> -->
    <a href="#" class="back-to-topp d-flex align-items-center justify-content-center">
      <i class="bi bi-arrow-up-short"></i>
    </a>
  </div>
</template>

<script>
import { initApp } from "../assets/js/main";
import Contact from "../components/main-page/contact.vue";
import services from "../components/main-page/counters.vue";
import Instructions from "../components/main-page/features.vue";
import AppFooter from "../components/main-page/footer.vue";
import AppHeader from "../components/main-page/header.vue";
import Hero from "../components/main-page/hero.vue";
// import Social from "../components/social-icons.vue";
import Systems from "../components/main-page/services.vue";
import Info from "../components/main-page/testimonials.vue";
// import Team from "../components/main-page/team.vue";

export default {
  components: {
    Contact,
    services,
    Instructions,
    AppFooter,
    AppHeader,
    Hero,
    Systems,
    // Social,
    // Team,
    Info,
  },
  mounted() {
    initApp();
  },
  data() {
    return {
      links: ["start", "social", "instructions", "systems"],
      current: null,
    };
  },
  methods: {
    scrollTo(link) {
      this.current = link;
      const el = document.getElementById(link);
      el.scrollIntoView({ block: "start", behavior: "smooth" });
      this.toggleMenu();
    },
    toggleMenu() {
      document.getElementById("menu").classList.toggle("open");
    },
  },
  computed: {
    siteData() {
      return this.$store.getters.siteData;
    },
  },
};
</script>

<style scoped lang="css">
@import "../assets/vendor/bootstrap/css/bootstrap.min.css";
@import "../assets/vendor/bootstrap-icons/bootstrap-icons.css";
@import "../assets/vendor/boxicons/css/boxicons.min.css";
@import "../assets/vendor/glightbox/css/glightbox.min.css";
@import "../assets/vendor/remixicon/remixicon.css";
@import "../assets/vendor/swiper/swiper-bundle.min.css";
@import "../assets/css/style.css";
@import "../assets/css/hero.css";

/*--------------------------------------------------------------
# Back to top button
--------------------------------------------------------------*/
.back-to-topp {
  position: fixed;
  visibility: hidden;
  opacity: 0;
  right: 15px;
  bottom: 15px;
  z-index: 996;
  background: #ffc451;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  transition: all 0.4s;
}
.back-to-topp i {
  font-size: 28px;
  color: #151515;
  line-height: 0;
}
.back-to-topp:hover {
  background: #151515;
}
.back-to-topp:hover i {
  color: #ffc451;
}
.back-to-topp.active {
  visibility: visible;
  opacity: 1;
}
</style>
