<template>
  <header id="header" class="fixed-top">
    <div class="container d-flex align-items-center justify-content-between">
      <h1 class="logo me-auto me-lg-0">
        <a href="#">
          <img src="../../assets/imgs/logo.png" alt="" class="" />
        </a>
      </h1>
      <!-- Uncomment below if you prefer to use an image logo -->
      <!-- <a href="index.html" class="logo me-auto me-lg-0"><img src="assets/img/logo.png" alt="" class="img-fluid"></a>-->

      <nav
        id="navbar"
        class="navbar order-last order-lg-0 justify-content-between"
        style="background: #0000"
      >
        <ul>
          <li><a class="nav-link scrollto active" href="#hero">Home</a></li>
          <li><a class="nav-link scrollto" href="#instructions">instructions</a></li>
          <li><a class="nav-link scrollto" href="#systems">systems</a></li>
          <li><a class="nav-link scrollto" href="#cta">payment</a></li>
          <li><a class="nav-link scrollto" href="#services">Services</a></li>
          <li><a class="nav-link scrollto" href="#info">Info</a></li>
          <li><a class="nav-link scrollto" href="#contact">Contact</a></li>
        </ul>
        <i style="float: right" class="bi bi-list mobile-nav-toggle"></i>
      </nav>
      <!-- .navbar -->

      <!-- <a href="#cta" class="get-started-btn scrollto">payment & transactions</a> -->
    </div>
  </header>
  <!-- End Header -->
</template>

<script>
export default {
  mounted() {},
};
</script>
