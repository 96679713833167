<template>
  <!-- ======= Features Section ======= -->
  <!-- <section id="features" class="features">
    <div class="container" data-aos="fade-up">
      <div class="row" dir="rtl">
        <img
          class="image col-lg-6"
          data-aos="fade-right"
          src="https://citizenpath.com/cpwp/wp-content/uploads/2018/08/form-i-485-instructions.jpg"
          alt=""
        />
        <div class="col-lg-6" data-aos="fade-left" data-aos-delay="100">
          <div
            v-for="(ins, i) in siteData.instructions.split(',')"
            :key="i"
            class="icon-box mt-5 mt-lg-0 d-flex justify-content-start align-items-center"
            data-aos="zoom-in"
            data-aos-delay="150"
            dir="rtl"
          >
            <i class="bx bx-pencil"></i>
            <h5>{{ ins }}</h5>
          </div>
        </div>
      </div>
    </div> -->

  <section id="instructions">
    <!-- Mission -->
    <div class="basic-4" data-aos="zoom-in-up">
      <div class="container">
        <div class="row">
          <div class="text-container">
            <h3>INSTRUCTIONS</h3>
            <hr style="height: 8px" class="hr-title" />
          </div>
        </div>
      </div>
    </div>
    <!-- end of mission -->

    <!-- Strengths -->
    <div id="strengths" class="basic-5 py-2 p-md-5">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <ul class="list-unstyled li-space-lg">
              <li
                v-for="(ins, i) in siteData.instructions.split(',')"
                :key="i"
                style="padding-bottom: 10px"
                class=""
                data-aos="fade-right"
                aos-delay="300"
              >
                <div class="media">
                  <div class="bullet">{{ i + 1 }}</div>
                  <div class="media-body">
                    <h4 style="color: #000">Instruction</h4>
                    <p style="line-height: 35px; word-space: 40px">
                      {{ ins }}
                    </p>
                  </div>
                </div>
                <hr class="hr-title" />
              </li>
            </ul>
          </div>
          <!-- end of col-lg-12 -->
        </div>
        <!-- end of row -->
      </div>
      <!-- end of container -->
    </div>
    <!-- end of basic-5 -->
    <!-- end of strengths -->
  </section>
  <!-- End Features Section -->
</template>

<script>
export default {
  computed: {
    siteData() {
      return this.$store.getters.siteData;
    },
  },
};
</script>

<style lang="scss" scoped>
@import url("../../assets/css/style2.css");
.hr-title {
  width: 60%;
  height: 4px;
  background: rgba(223, 129, 6, 0.714);
  margin: 2px auto;
}
</style>
