<template>
  <!-- <div id="social" class="d-flex justify-content-center"> -->
  <div class="_footer-icons">
    <a @click="gotoLink('facebook')"><i class="fa fa-facebook"></i></a>
    <!-- <a @click="gotoLink('telegram')"><i class="fa fa-telegram"></i></a> -->
    <a @click="gotoLink('whatsapp')"><i class="fa fa-whatsapp"></i></a>
    <a @click="gotoLink('email')"><i class="fa fa-envelope"></i></a>
  </div>
  <!-- </div> -->
  <!-- end of services -->
</template>

<style lang="scss" scoped>
._footer-icons {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
._footer-icons a {
  display: inline-block;
  width: 70px;
  height: 70px;
  cursor: pointer;
  // background-color: #33383b;

  // box-shadow: 1px 1px 12px 1px #fff;
  border-radius: 2px;
  border: 4px solid rgba(255, 255, 255, 0.564);
  margin: 22px;

  font-size: 40px;
  color: #ffffff !important;
  text-align: center;
  line-height: 35px;

  margin-right: 3px;
  margin-bottom: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  i {
    transition: all 0.4s ease-in-out;
  }
  &:hover {
    color: #fff;
    i {
      transform: rotate(360deg);
    }
  }
  // align-content: center;
}
</style>

<script>
export default {
  computed: {
    siteData() {
      return this.$store.getters.siteData;
    },
  },
  methods: {
    gotoLink(link) {
      if (link === "whatsapp") {
        window.open(
          "https://api.whatsapp.com/send?phone=+2" + this.siteData.whatsapp,
          "_blank"
        );
      }

      if (link === "email") {
        window.open(
          "mailto:" + this.siteData.email + "?subject=Subject&body=message%20goes%20here",
          "_blank"
        );
      }

      if (link === "telegram") {
        window.open(this.siteData.telegram);
      }

      if (link === "facebook") {
        console.log(navigator.platform);
        if (
          // /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(
          //   navigator.userAgent
          // )
          !navigator.platform.includes("Win32")
        ) {
          window.open("fb://facewebmodal/f?href=" + this.siteData.facebook);
        } else {
          window.open(this.siteData.facebook, "_blank");
        }
      }
    },
  },
};
</script>
