<template>
  <section class="page-section" id="about">
    <div class="container">
      <div class="text-center p-5">
        <h2 class="section-heading text-uppercase">About</h2>
        <h5 class="section-subheading text-muted">Some information about our company.</h5>
      </div>
      <br /><br />
      <ul class="timeline">
        <li
          v-for="(info, i) in siteData.infos"
          :key="i"
          :class="{ 'timeline-inverted': i % 2 > 0 }"
          :data-aos="i % 2 > 0 ? 'fade-left' : 'fade-right'"
        >
          <div class="timeline-image">
            <img class="rounded-circle img-fluid" :src="getImage(i)" alt="..." />
          </div>
          <div class="timeline-panel">
            <div class="timeline-heading">
              <h4>{{ info.title }}</h4>
            </div>
            <div class="timeline-body">
              <p class="text-muted">{{ info.description }}</p>
            </div>
          </div>
        </li>
        <li class="timeline-inverted">
          <div class="timeline-image">
            <h4>
              Be Part
              <br />
              Of Our
              <br />
              Story!
            </h4>
          </div>
        </li>
      </ul>
    </div>
  </section>
  <!-- End Testimonials Section -->
</template>

<style lang="css" scoped>
@import url("../../assets/css/style3.css");
</style>

<script>
export default {
  computed: {
    siteData() {
      return this.$store.getters.siteData;
    },
  },
  methods: {
    getImage(i) {
      let exists = false;
      try {
        require("../../assets/img/about/" + (i + 1) + ".jpg");
        exists = true;
      } catch (e) {
        exists = false;
      }
      return exists
        ? require("../../assets/img/about/" + (i + 1) + ".jpg")
        : require("../../assets/img/about/5.jpg");
    },
  },
};
</script>
