<template>
  <section id="systems" class="services" dir="ltr">
    <!-- <div
      v-for="(cat, i) in categories"
      :key="i"
      class="container mt-5"
      data-aos="fade-up"
    >
      <div class="section-title">
        <h2>Services</h2>
        <p>{{ cat.name }}</p>
      </div>

      <div class="row">
        <div
          v-for="system in filterSystems(cat.number)"
          :key="system._id"
          @click="$router.push('/system-details/' + system._id)"
          class="col-lg-4 col-md-6 d-flex align-items-stretch"
          data-aos="zoom-in"
          data-aos-delay="100"
        >
          <div class="icon-box">
            <div class="icon"><i class="bx bxl-dribbble"></i></div>
            <h4>
              <a href="">{{ system.title }}</a>
            </h4>
            <p>
              {{ system.subtitle }}
            </p>
          </div>
        </div>
      </div>
    </div> -->

    <div v-for="(cat, i) in categories" :key="i" class="cat" style="margin: 2rem auto">
      <h1 class="text-center my-5">{{ cat.name }}</h1>
      <hr class="hr-title" />
      <!--system start-->
      <div
        v-for="(system, s) in filterSystems(cat.number)"
        :key="system._id"
        id="details"
        class="basic-1 p-1 p-md-5"
        data-aos="fade-in"
      >
        <div class="container">
          <div
            class="row justify-content-between align-items-center"
            :dir="s % 2 > 0 ? 'rtl' : 'ltr'"
          >
            <div class="col-lg-6 col-xl-5" data-aos="fade-left">
              <!-- <img class="img-fluid" :src="system.image" alt="alternative" /> -->
              <display-content :src="system.image" />
              <!-- end of image-container -->
            </div>
            <div class="col-lg-6 col-xl-7" data-aos="fade-right">
              <div class="text-container">
                <h2 style="color: #000">{{ system.title }}</h2>
                <hr class="hr-heading" style="height: 3px" />
                <h3 style="color: #777">{{ system.subtitle }}</h3>
                <p>{{ system.sections[0].text | trancate(330) }}</p>
                <a
                  style="cursor: pointer"
                  class="btn-solid-reg popup-with-move-anim"
                  @click="$router.push('/system-details/' + system._id)"
                  >Details</a
                >
              </div>
              <!-- end of text-container -->
            </div>
            <!-- end of col -->
          </div>
          <!-- end of row -->
        </div>
        <hr
          class=""
          style="height: 3px; background: purple; width: 70%; margin: 22px auto"
        />
        <!-- end of container -->
      </div>
      <!-- end of system -->
    </div>
  </section>
</template>

<script>
import axios from "axios";
import DisplayContent from "../display-question";
export default {
  components: {
    DisplayContent,
  },
  created() {
    axios.get("/getSystemsForAdmin").then((res) => {
      this.systems = res.data.systems;
      console.log(this.systems);
    });
  },
  data() {
    return {
      systems: [],
    };
  },
  computed: {
    categories() {
      return this.$store.getters.categories;
    },
  },
  methods: {
    filterSystems(cat) {
      return this.systems.filter((sys) => sys.category == cat);
    },
  },
};
</script>

<style scoped>
.hr-title {
  width: 60%;
  height: 8px;
  background: rgb(223, 129, 6);
  margin: 2px auto;
}
</style>
